function formatDate(date, server = false) {
  if (!date) return '';
  let arrDate = date.split(/[/ :-]/);

  if (!server) return `${arrDate[2]}/${arrDate[1]}/${arrDate[0]}`;
  else return `${arrDate[2]}-${arrDate[1]}-${arrDate[0]}`;
}
async function onBtnExportAll(items){

  if(items.length !== 0){
      this.Loading = true;
      let itemsArray = [];
      await items.map((item,index) => {
        let objeto = {};
        objeto['#'] = index+1;
        objeto[this.$t('label.name')] = item.Name;
        objeto[this.$t('label.lastName')] = item.LastName;
        objeto[this.$t('label.IdNumber')] = item.NumberId;
        objeto[this.$t('label.birthDate')] = this.formatDate(item.BirthDate),
        objeto[this.$t('label.stowageRole')] = item.StowageRoleName;
        objeto[this.$t('label.country')] = item.CountryName;
        objeto[this.$t('label.municipality')] = item.MunicipalityName;
        objeto[this.$t('label.parish')] = item.ParishName;
        objeto[this.$t('label.address')] = item.Address;
        objeto[this.$t('label.mainPhone')] = item.PrimaryPhone;
        objeto[this.$t('label.secondaryPhone')] = item.SecondaryPhone;
        objeto[this.$t('label.mainEmail')] = item.PrimaryEmail;
        objeto[this.$t('label.secondaryEmail')] = item.SecondaryEmail;
        objeto[this.$t('label.errors')] = !Array.isArray(item.JsonError)
         ? item.JsonError.ErrorJson.map((error) => {
            return `${this.$t("label.column")} ${error.ColumnName}: ${error.ColumnValue} ${this.$i18n.locale == 'es' ? error.ErrorMessage : error.ErrorMessageEn}`;
           }).join(",")
         : '',
        itemsArray.push(objeto);
      });
      await this.getExcelArray(itemsArray, this.$t('label.reportStaffList'), true, items[0].ClientName);
      this.Loading = false;
  }else{
    this.$notify({  
      group: 'container',
      title: '¡Error!',
      text: this.$t('label.noRecordsAvailbleReport'),
      type: "error" 
    });
  }
}

async function onBtnExportErrors(items){

  if(items.length !== 0){
      this.Loading = true;
      let itemsArray = [];
      await items.map((item,index) => {
        if (!Array.isArray(item.JsonError)) {
        let objeto = {};
          objeto['#'] = index+1;
          objeto[this.$t('label.name')] = item.Name;
          objeto[this.$t('label.lastName')] = item.LastName;
          objeto[this.$t('label.IdNumber')] = item.NumberId;
          objeto[this.$t('label.birthDate')] = this.formatDate(item.BirthDate),
          objeto[this.$t('label.stowageRole')] = item.StowageRoleName;
          objeto[this.$t('label.country')] = item.CountryName;
          objeto[this.$t('label.municipality')] = item.MunicipalityName;
          objeto[this.$t('label.parish')] = item.ParishName;
          objeto[this.$t('label.address')] = item.Address;
          objeto[this.$t('label.mainPhone')] = item.PrimaryPhone;
          objeto[this.$t('label.secondaryPhone')] = item.SecondaryPhone;
          objeto[this.$t('label.mainEmail')] = item.PrimaryEmail;
          objeto[this.$t('label.secondaryEmail')] = item.SecondaryEmail;
          objeto[this.$t('label.errors')] = !Array.isArray(item.JsonError)
          ? item.JsonError.ErrorJson.map((error) => {
              return `${this.$t("label.column")} ${error.ColumnName}: ${error.ColumnValue} ${this.$i18n.locale == 'es' ? error.ErrorMessage : error.ErrorMessageEn}`;
            }).join(",")
          : '',
          itemsArray.push(objeto);
        }
      });
      await this.getExcelArray(itemsArray, this.$t('label.reportStaffList'), true, items[0].ClientName);
      this.Loading = false;
  }else{
    this.$notify({  
      group: 'container',
      title: '¡Error!',
      text: this.$t('label.noRecordsAvailbleReport'),
      type: "error" 
    });
  }
  this.Loading = false;
}
export default {
  methods: {
    formatDate,
    onBtnExportAll,
    onBtnExportErrors,
  },
}